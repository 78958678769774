import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import Intercom from '@intercom/messenger-js-sdk'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Box } from '@mui/material'
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react'
import axios from 'axios'
import { minutesToMilliseconds } from 'date-fns'
import { UnregisterCallback } from 'history'
import { isEmpty, isNil, isObject } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { CustomConfirmModal } from '@mth/components/CustomConfirmModal/CustomConfirmModal'
import { MthLoading } from '@mth/components/MthLoading/MthLoading'
import { styles, transitionsText } from '@mth/components/SideMenu/styles'
import {
  UNKNOWN,
  OPERATIONAL_MAINTENANCE_SCREEN,
  EPIC_4594_STORY_4663,
  EMPTY_STRING,
  STATES_WITH_ABBREVIATION,
  KILL_SWITCH_INTERCOM,
  EPIC_4335_BUG_5895,
  KILL_SWITCH_ENABLE_ITR_HUBSPOT,
  KILL_SWITCH_ENABLE_ITR_NEW_PLATFORM,
  EPIC_6112,
  OPERATIONAL_INTERCOM_USER_ID,
  EPIC_2966_TASK_6314,
} from '@mth/constants'
import { AnnouncementPopupModal } from '@mth/core/components/announcementPopups/AnnouncementPopupModal'
import { HubspotModal } from '@mth/core/components/hubspotModal'
import { ITRModal } from '@mth/core/components/itrModal/itrModal'
import { AutoUpdateBanner } from '@mth/core/components/shared/AutoUpdateBanner/AutoUpdateBanner'
import { STORAGE, MthRoute, RoleLevel, TEXT_SIZE } from '@mth/enums'
import { SNOWPACK_PUBLIC_MAINTENANCE_PAGE_POLLING, SNOWPACK_PUBLIC_MAINTENANCE_PAGE_URL } from '@mth/envs'
import { useGetITRUsers } from '@mth/hooks'
import { useAnnouncementsPopup } from '@mth/hooks/useAnnouncementPopup/useAnnouncementPopup'
import { UserRegion } from '@mth/models'
import { AuthContext } from '@mth/providers/AuthProvider/AuthContext'
import {
  modalConfirmDialogBeforeLeave,
  regionSelectedDialogBeforeLeave,
  confirmDialogBeforeLeave,
  isLoggingOut,
} from '@mth/providers/Store/State'
import { logoutUser, validateSession } from '@mth/services/jwt-session/jwt-session.service'
import { lazyWithRetry, setStorageValue } from '@mth/utils'
import { getMeQuery } from './services'
import { Flexbox } from '../components/Flexbox/Flexbox'
import { TabContext, UserContext, UserInfo, userRegionState } from '../providers/UserContext/UserProvider'
import { LoadingScreen } from '../screens/LoadingScreen/LoadingScreen'

const SideMenu = lazyWithRetry(() => import('../components/SideMenu/SideMenu'))
const AdminSideMenu = lazyWithRetry(() => import('../components/SideMenu/AdminSideMenu'))
const TeacherSideMenu = lazyWithRetry(() => import('@mth/components/SideMenu/TeacherSideMenu'))
const StudentSideMenu = lazyWithRetry(() => import('@mth/components/SideMenu/StudentSideMenu'))
const ObserverSideMenu = lazyWithRetry(() => import('@mth/components/SideMenu/ObserverSideMenu'))

const AppBar = lazyWithRetry(() => import('../components/AppBar/AppBar'))
const AdminAppBar = lazyWithRetry(() => import('../components/AdminAppBar/AdminAppBar'))
const TeacherAppBar = lazyWithRetry(() => import('@mth/components/TeacherAppBar/TeacherAppBar'))
const StudentAppBar = lazyWithRetry(() => import('@mth/components/StudentAppBar/StudentAppBar'))

const MaintenancePage = lazyWithRetry(() => import('../components/MaintenancePage/MaintenancePage'))

const Routes = lazyWithRetry(() => import('../router/Routes'))
const AdminRoutes = lazyWithRetry(() => import('../router/AdminRoutes'))
const TeacherRoutes = lazyWithRetry(() => import('../router/TeacherRoutes'))
const StudentRoutes = lazyWithRetry(() => import('../router/StudentRoutes'))
const ObserverRoutes = lazyWithRetry(() => import('../router/ObserverRoutes'))
const UnauthenticatedRoutes = lazyWithRetry(() => import('../router/UnauthenticatedRoutes'))

const MasqueradeFooter = lazyWithRetry(() => import('../components/MasqueradeFooter/MasqueradeFooter'))

const VALIDATE_SESSION_INTERVAL = minutesToMilliseconds(
  Number(import.meta.env.VITE_PUBLIC_VALIDATE_SESSION_INTERVAL) || 5,
)

export const Root: React.FC = () => {
  const { flagsReady } = useFlagsStatus()
  const epic4335Bug5895 = useFlag(EPIC_4335_BUG_5895)
  const epic2966Story6314 = useFlag(EPIC_2966_TASK_6314)
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(true)

  const useStyles = makeStyles((theme: Theme) => ({
    content: {
      [theme.breakpoints.down('xs')]: {
        marginLeft: '0',
      },
      marginLeft: isSideMenuOpen ? styles.sideMenu.sideMenuOpen.sizePx : styles.sideMenu.sideMenuClose.sizePx,
      transition: `margin-left width ${transitionsText}`,
    },
  }))
  const classes = useStyles()
  const { me, setMe } = useContext(UserContext)
  const { setTab, setVisitedTabs } = useContext(TabContext)
  const { loading, data } = useQuery(getMeQuery, { skip: (!epic4335Bug5895 && !!me) || !flagsReady })
  const [isSuper, setIsSuper] = useState(false)
  const [isMaintenance, setIsMaintenance] = useState<boolean>(false)
  const [versionUpdateRequired, setVersionUpdateRequired] = useState(false)
  const operationalMaintenaceScreen = useFlag(OPERATIONAL_MAINTENANCE_SCREEN)
  const [showAnnouncements, setShowAnnouncements] = useState<boolean>(false)
  const [nextLocation, setNextLocation] = useState<Location>()
  const unblockRef = useRef<UnregisterCallback | null>(null)
  const epic6112 = useFlag(EPIC_6112)
  const killswitchItrHubspot = useFlag(KILL_SWITCH_ENABLE_ITR_HUBSPOT)
  const killswitchItrNewPlatform = useFlag(KILL_SWITCH_ENABLE_ITR_NEW_PLATFORM)
  const operationalIntercomUserId = useFlag(OPERATIONAL_INTERCOM_USER_ID)

  const location = useLocation()
  const history = useHistory()
  const epic4594Story4663 = useFlag(EPIC_4594_STORY_4663)
  const killSwitchIntercom = useFlag(KILL_SWITCH_INTERCOM)

  const {
    showITRModal,
    canShowITRModal,
    setForceShowITRModal,
    submitITR,
    onCancel,
    uniqueId,
    showFinalMessage,
    selectedItrOption,
    setSelectedItrOption,
    extraFieldContent,
    setExtraFieldContent,
    itrHubspotLoading,
    showErrorMessage,
    showSubmissionError,
    hubspotAnswer,
    submitNewPlatformITR,
  } = useGetITRUsers(Number(me?.profile?.person_id), killswitchItrNewPlatform)

  const pullAnnouncements = useMemo(() => {
    return !!(isObject(location?.state) && 'pullAnnouncements' in location?.state && location.state?.pullAnnouncements)
  }, [location])

  const [_confirmDialogBeforeLeave, setConfirmDialogBeforLeave] = useRecoilState(confirmDialogBeforeLeave)
  const [_modalConfirmDialogBeforeLeave, setModalConfirmDialogBeforeLeave] =
    useRecoilState(modalConfirmDialogBeforeLeave)
  const [_regionSelectedDialogBeforeLeave, setRegionSelectedDialogBeforeLeave] = useRecoilState(
    regionSelectedDialogBeforeLeave,
  )
  const [_isLoggingOut, setIsLoggingOut] = useRecoilState(isLoggingOut)
  const { signOut } = useContext(AuthContext)
  const [, setSelected] = useRecoilState(userRegionState)

  // @ts-ignore
  useEffect(() => {
    if (_confirmDialogBeforeLeave.activate) {
      const handleLocationChange = (newLocation: Location) => {
        if (!_modalConfirmDialogBeforeLeave) {
          setModalConfirmDialogBeforeLeave(true)
          setNextLocation(newLocation)
          return false // Block navigation
        }
        return true // Allow navigation if already showing the modal
      }

      // Add event listener to block navigation
      // @ts-ignore
      unblockRef.current = history.block((tx) => {
        // eslint-disable-next-line @typescript-eslint/ban-types
        if (!handleLocationChange(tx as unknown as Location)) {
          return false
        }
      })

      // Cleanup the block when component unmounts
      return () => {
        if (unblockRef.current) {
          unblockRef.current()
        }
      }
    }
  }, [history, nextLocation, _modalConfirmDialogBeforeLeave, _confirmDialogBeforeLeave])

  const confirmLeavePage = async () => {
    if (!isNil(_regionSelectedDialogBeforeLeave)) {
      setSelected(_regionSelectedDialogBeforeLeave)
      setStorageValue(STORAGE.SELECTED_REGION, _regionSelectedDialogBeforeLeave)
      setMe((prev: UserInfo | null) => {
        if (!prev) {
          return {
            selectedRegionId: _regionSelectedDialogBeforeLeave.region_id,
            masquerade: false,
          }
        }
        return {
          ...prev,
          selectedRegionId: _regionSelectedDialogBeforeLeave.region_id,
        }
      })
      setRegionSelectedDialogBeforeLeave(null)
    }
    if (_isLoggingOut) {
      await logoutUser()
      setMe(null)
      signOut()
      const logoutDashboard = { ...window.location, pathname: MthRoute.DASHBOARD }
      setNextLocation(logoutDashboard)
      setIsLoggingOut(false)
    }
    setConfirmDialogBeforLeave({ activate: false, header: EMPTY_STRING, content: EMPTY_STRING })
    setModalConfirmDialogBeforeLeave(false)
    if (unblockRef.current) {
      unblockRef.current() // Unblock navigation
    }
    if (nextLocation?.pathname) history.push(nextLocation.pathname) // Continue navigation
  }

  const cancelLeavePage = () => {
    setRegionSelectedDialogBeforeLeave(null)
    setIsLoggingOut(false)
    setModalConfirmDialogBeforeLeave(false)
    setNextLocation(undefined) // Cancel navigation
  }

  const renderConfirmDialogBeforeLeaveModal = () => {
    return (
      <CustomConfirmModal
        header={_confirmDialogBeforeLeave.header}
        headerFontSize={TEXT_SIZE.EXTRA_LARGE}
        content={_confirmDialogBeforeLeave.content}
        cancelBtnTitle={_confirmDialogBeforeLeave.cancelBtnTitle}
        confirmBtnTitle={_confirmDialogBeforeLeave.confirmBtnTitle}
        handleConfirmModalChange={(isOk: boolean) => {
          if (isOk) {
            confirmLeavePage()
          } else {
            cancelLeavePage()
          }
        }}
      />
    )
  }

  const regionId = useMemo(
    () => Number(me?.selectedRegionId) || Number(me?.userRegion?.at(-1)?.region_id),
    [me?.selectedRegionId, me?.userRegion],
  )

  const announcements = useAnnouncementsPopup(pullAnnouncements, me?.level, regionId)

  useEffect(() => {
    setShowAnnouncements(announcements.length > 0)
  }, [announcements])

  // @ts-ignore
  useEffect(() => {
    if (operationalMaintenaceScreen) {
      fetchData()
      const intervalId = setInterval(() => {
        fetchData()
      }, SNOWPACK_PUBLIC_MAINTENANCE_PAGE_POLLING)

      return () => clearInterval(intervalId)
    } // Cleanup
  }, [operationalMaintenaceScreen])

  async function fetchData() {
    const config = {
      url: SNOWPACK_PUBLIC_MAINTENANCE_PAGE_URL,
      method: 'GET',
    }

    axios(config).then((response) => {
      setIsMaintenance(response.data.maintenanceStatus)
    })
  }
  const closeShowAnnouncements = () => {
    // @ts-ignore
    const state = { ...history.location.state }
    delete state.pullAnnouncements
    history.replace({ ...history.location, state })
    setShowAnnouncements(false)
  }

  useEffect(() => {
    if (!epic2966Story6314) return
    const interval = setInterval(() => {
      validateSession()
    }, VALIDATE_SESSION_INTERVAL)

    return () => clearInterval(interval)
  }, [epic2966Story6314])

  useEffect(
    () => {
      if (!loading && me === null && data !== undefined) {
        setTab({
          currentTab: 0,
        })

        //  Sort user regions by region name
        const regions: UserRegion[] = []
        data.me.userRegion.forEach((region: UserRegion) => {
          let i
          for (i = 0; i < regions.length; i++) {
            if ((regions[i].regionDetail.name ?? '') > (region.regionDetail.name ?? '')) break
          }
          regions.splice(i, 0, region)
        })
        //  Replace userRegion array with the sorted one.
        setMe({
          ...data.me,
          userRegion: regions,
        })
        setVisitedTabs([])
        setIsSuper(Number(data.me?.level) === 1)

        // Set User information for the RUM session when the user is not Masquerade
        if (localStorage.getItem('masquerade') === null) {
          datadogRum.setUser({
            id: data.me?.user_id,
            email: data.me?.email,
            name: `${data.me.first_name} ${data.me.last_name}`,
            role: data.me?.role?.name || UNKNOWN,
            region:
              regions.length > 0
                ? regions
                    .map((r) => r?.regionDetail?.name)
                    .filter((r) => !isNil(r))
                    .join(',')
                : UNKNOWN,
          })
        }
      }
    },
    epic4335Bug5895 ? [loading, data] : [loading, data, me],
  )

  const renderSideMenu = (me: UserInfo) => {
    const sideMenuProps = {
      isVersionUpdateRequired: versionUpdateRequired,
      isSideMenuOpen,
      setIsSideMenuOpen,
    }
    if (me.level === RoleLevel.TEACHER) {
      return <TeacherSideMenu {...sideMenuProps} />
    } else if (me.level === RoleLevel.OBSERVER) {
      return <ObserverSideMenu {...sideMenuProps} />
    } else if (me.level === RoleLevel.STUDENT) {
      return <StudentSideMenu {...sideMenuProps} />
    }
    return <SideMenu {...sideMenuProps} setShowITRModal={setForceShowITRModal} canShowITRModal={canShowITRModal} />
  }

  const renderAppBar = (me: UserInfo) => {
    if (me?.level === RoleLevel.ADMIN) {
      return <AdminAppBar />
    } else if (me?.level === RoleLevel.TEACHER) {
      return <TeacherAppBar />
    } else if (me?.level === RoleLevel.STUDENT) {
      return <StudentAppBar />
    }

    if (!killSwitchIntercom) {
      const defaultState = me?.userRegion?.at(-1)?.regionDetail?.name
      const stateAbbreviation =
        defaultState && STATES_WITH_ABBREVIATION[defaultState] ? STATES_WITH_ABBREVIATION[defaultState] : 'QA'
      const personId = String(me?.profile?.person_id).padStart(7, '0')
      const userLevel = me?.level === RoleLevel.PARENT ? 'P' : 'O'
      const uniqueId = me?.profile?.uniqueId ?? `${stateAbbreviation}-${userLevel}-${personId}`

      let userId
      if (operationalIntercomUserId) {
        if (!isEmpty(me?.profile?.new_platform_uid)) {
          userId = me?.profile?.new_platform_uid
        } else {
          userId = `no-new-plaform-uid-${uniqueId}`
        }
      } else {
        userId = uniqueId
      }

      Intercom({
        api_base: import.meta.env.VITE_PUBLIC_INTERCOM_BASE,
        app_id: import.meta.env.VITE_PUBLIC_INTERCOM_ID,
        user_id: userId,
        name: `${me?.first_name ?? ''} ${me?.last_name ?? ''}`.trim(),
        email: me?.email,
        phone: me?.profile?.phone?.number,
        infocenter_unique_id: uniqueId,
      })
    }

    return <AppBar setShowITRModal={setForceShowITRModal} canShowITRModal={canShowITRModal} />
  }

  const renderRoutes = (me: UserInfo) => {
    if (me.level === RoleLevel.TEACHER) {
      return <TeacherRoutes />
    } else if (me.level === RoleLevel.OBSERVER) {
      return <ObserverRoutes />
    } else if (me?.level === RoleLevel.STUDENT) {
      return <StudentRoutes />
    }
    return <Routes />
  }

  const autoUpdaterBannerProps = {
    versionUpdateRequired,
    setVersionUpdateRequired,
  }

  if (isMaintenance) {
    return (
      <React.Suspense fallback={<MthLoading />}>
        <MaintenancePage />
      </React.Suspense>
    )
  } else if (!flagsReady || (loading && !me)) {
    return <LoadingScreen />
  } else if (me !== null) {
    if (!isSuper) {
      return (
        <React.Suspense fallback={<MthLoading />}>
          <AutoUpdateBanner {...autoUpdaterBannerProps} />
          <Box sx={{ height: '100%', flex: 1 }} alignItems={'center'}>
            {renderSideMenu(me)}
            <Box display='flex' flex={1} flexDirection={'column'} textAlign={'center'} justifyContent='space-between'>
              <div className={classes.content}>
                {renderAppBar(me)}
                <Box sx={{ marginTop: { xs: '65px', sm: 0 }, marginBottom: { xs: '15px', sm: 0 } }}>
                  {renderRoutes(me)}
                </Box>
              </div>
              {localStorage.getItem('masquerade') !== null && <MasqueradeFooter me={me} />}
              {announcements && showAnnouncements && epic4594Story4663 && (
                <AnnouncementPopupModal onClose={closeShowAnnouncements} announcementPopup={announcements[0]} />
              )}
              {showITRModal && epic6112 && killswitchItrNewPlatform && (
                <ITRModal
                  onCancel={() => onCancel()}
                  selectedItrOption={hubspotAnswer}
                  onSubmit={submitNewPlatformITR}
                ></ITRModal>
              )}
              {showITRModal && killswitchItrHubspot && !killswitchItrNewPlatform && (
                <HubspotModal
                  isOpen={showITRModal}
                  onCancel={() => onCancel()}
                  regionId={me?.userRegion?.[0]?.region_id}
                  parentUniqueId={uniqueId ?? EMPTY_STRING}
                  email={me?.profile?.email ?? EMPTY_STRING}
                  onSubmit={submitITR}
                  showFinalMessage={showFinalMessage}
                  selectedItrOption={selectedItrOption}
                  setSelectedItrOption={setSelectedItrOption}
                  extraFieldContent={extraFieldContent}
                  setExtraFieldContent={setExtraFieldContent}
                  itrHubspotLoading={itrHubspotLoading}
                  showErrorMessage={showErrorMessage}
                  showSubmissionError={showSubmissionError}
                ></HubspotModal>
              )}
              {_modalConfirmDialogBeforeLeave && renderConfirmDialogBeforeLeaveModal()}
            </Box>
          </Box>
        </React.Suspense>
      )
    } else {
      return (
        <React.Suspense fallback={<MthLoading />}>
          <AutoUpdateBanner {...autoUpdaterBannerProps} />
          <Flexbox flexDirection='row'>
            <AdminSideMenu
              isVersionUpdateRequired={versionUpdateRequired}
              isSideMenuOpen={isSideMenuOpen}
              setIsSideMenuOpen={setIsSideMenuOpen}
            />
            <Flexbox flexDirection='column'>
              <div
                style={{
                  marginLeft: isSideMenuOpen
                    ? styles.sideMenu.sideMenuOpen.sizePx
                    : styles.sideMenu.sideMenuClose.sizePx,
                  transition: `margin-left width ${transitionsText}`,
                }}
              >
                <AdminAppBar />
                <AdminRoutes />
              </div>
              {localStorage.getItem('masquerade') !== null && <MasqueradeFooter me={me} />}
              {announcements && showAnnouncements && epic4594Story4663 && (
                <AnnouncementPopupModal onClose={closeShowAnnouncements} announcementPopup={announcements[0]} />
              )}
              {_modalConfirmDialogBeforeLeave && renderConfirmDialogBeforeLeaveModal()}
            </Flexbox>
          </Flexbox>
        </React.Suspense>
      )
    }
  } else {
    return (
      <React.Suspense fallback={<MthLoading />}>
        <AutoUpdateBanner {...autoUpdaterBannerProps} />
        <UnauthenticatedRoutes />
      </React.Suspense>
    )
  }
}
