import { datadogLogs } from '@datadog/browser-logs'
import axios from 'axios'
import { isNil } from 'lodash'
import { SNOWPACK_PUBLIC_API_USERS_URL } from '@mth/envs'

export const loginUser = async (username: string, password: string) => {
  try {
    const url = `${SNOWPACK_PUBLIC_API_USERS_URL}/login`
    return await axios.post(url, { username, password }, { withCredentials: true })
  } catch (error) {
    const message = `Error logging in the user: ${username}`
    handleAxiosError(error, message)
    throw error
  }
}

export const logoutUser = async () => {
  try {
    const url = `${SNOWPACK_PUBLIC_API_USERS_URL}/logout`
    return await axios.post(url, {}, { withCredentials: true })
  } catch (error) {
    const message = 'Error logging out'
    handleAxiosError(error, message)
    throw new Error(message)
  }
}

export const refreshToken = async (setCredentials: (param: string) => void, logout: () => void) => {
  const url = `${SNOWPACK_PUBLIC_API_USERS_URL}/refresh-token`
  try {
    const { data } = await axios.post(url, {}, { withCredentials: true })
    if (data && data.jwt) {
      console.warn('Refreshing token')
      localStorage.setItem('JWT', data.jwt)
      setCredentials(data.jwt)
      return data.jwt
    } else {
      console.error('JWT not found in response')
      return null
    }
  } catch (error) {
    datadogLogs.logger.error('Error during token refresh', { error })
    logout()
    return null
  }
}

const handleAxiosError = (error: unknown, message: string) => {
  if (axios.isAxiosError(error) && error.code === '401') {
    datadogLogs.logger.warn(message, {
      responseStatus: error.response?.status,
    })
  } else {
    datadogLogs.logger.error(message, { error })
  }
  throw error
}

export const validateSession = async () => {
  const currentPath = window.location.pathname
  const searchParams = new URLSearchParams(window.location.search)
  const expiration = searchParams.get('expiration')
  try {
    const token = localStorage.getItem('masquerade') ?? localStorage.getItem('JWT')
    if (isNil(token)) throw new Error('Token is empty')

    const url = `${SNOWPACK_PUBLIC_API_USERS_URL}/validate-session`

    await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (expiration) window.location.href = expiration
  } catch (error) {
    if (
      (axios.isAxiosError(error) && error.response?.status === 401) ||
      (error instanceof Error && error.message.includes('Token is empty'))
    ) {
      datadogLogs.logger.warn('Session expired.')
      const isLoginPage = ['/login', '/'].includes(currentPath)
      const shouldSkipRedirect = isLoginPage && expiration

      if (!shouldSkipRedirect) {
        await logoutUser()
        const reloadUrl = expiration ? currentPath : `/?expiration=${currentPath}`
        window.location.href = reloadUrl
      }
    } else {
      handleAxiosError(error, 'Error validating session')
    }
  }
}
